import React, { useEffect, useState } from "react";
import { timecodeToSeconds } from "../TimeCodeConverter/index";
import styled from "styled-components";
import COLORS from "../../../assets/Colors";
import PlayerControls from "../PlayerControls";
import ProgresBar from "../PlayerControls/ProgresBar";
import { secondsToTimecode } from "../TimeCodeConverter/secondsToTimecodeNew";

const MediaPlayer = ({
  mediaDuration,
  videoRef,
  rollBackValueInSeconds,
  mediaUrl,
  timecodeOffset,
  hookSeek,
  mediaType,
  title,
  setHookSeek,
  isPlaying,
  setIsPlaying,
  duration,
}) => {
  const [playbackRate, setPlaybackRate] = useState(1);
  const [isMute, setIsMute] = useState(false);
  let slow_down = [0.85, 0.75, 0.5, 0.25, 0.2];
  const [currentIndex, setCurrentIndex] = useState(0);
  const [currentValue, setCurrentValue] = useState(slow_down[currentIndex]);
  let speed_up = [1.25, 1.5, 1.75];
  const [currentSpeedIndex, setCurrentSpeedIndex] = useState(0);
  const [currentSpeedValue, setCurrentSpeedValue] = useState(
    speed_up[currentSpeedIndex],
  );

  const pauseMedia = () => {
    setIsPlaying(false);
    videoRef.current.pause();
  };

  const playMedia = () => {
    setIsPlaying(
      {
        isPlaying: true,
      },
      videoRef.current.play(),
    );
  };

  useEffect(() => {
    if (hookSeek) {
      changeCurrentTime(hookSeek);
      setHookSeek(0);
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hookSeek]);

  useEffect(() => {
    const callback = (event) => {
      if ((event.metaKey || event.altKey) && event.code === "KeyK") {
        if (!isPlaying) {
          playMedia();
        } else {
          pauseMedia();
        }
      } else if ((event.metaKey || event.altKey) && event.code === "KeyL") {
        skipForward();
      } else if ((event.metaKey || event.altKey) && event.code === "KeyJ") {
        skipBackward();
      } else if ((event.metaKey || event.altKey) && event.code === "KeyR") {
        rollBack();
      } else if (event.code === "F1") {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % slow_down.length);
        setCurrentValue(slow_down[(currentIndex + 1) % slow_down.length]);
        videoRef.current.playbackRate = currentValue;
      } else if (event.code === "F2") {
        setCurrentSpeedIndex((prevIndex) => (prevIndex + 1) % speed_up.length);
        setCurrentSpeedValue(
          speed_up[(currentSpeedIndex + 1) % speed_up.length],
        );
        videoRef.current.playbackRate = currentSpeedValue;
      } else if (event.code === "F3") {
        event.preventDefault();
        skipBackward();
      } else if (event.code === "F4") {
        skipForward();
      } else if (event.code === "F5") {
        event.preventDefault();
        videoRef.current.playbackRate = 1;
      } else if (event.code === "Escape") {
        event.preventDefault();
        if (!isPlaying) {
          playMedia();
        } else {
          pauseMedia();
        }
      }
    };
    document.addEventListener("keydown", callback);
    return () => {
      document.removeEventListener("keydown", callback);
    }; // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPlaying, currentIndex, slow_down, currentSpeedIndex, speed_up]);

  const changeCurrentTime = (newCurrentTime) => {
    if (newCurrentTime !== "" && newCurrentTime !== null) {
      // hh:mm:ss:ff - mm:ss - m:ss - ss - seconds number or string and hh:mm:ss
      const newCurrentTimeInSeconds = timecodeToSeconds(newCurrentTime);
      const videoRefs = videoRef.current;

      if (videoRefs.readyState === 4) {
        videoRefs.currentTime = newCurrentTimeInSeconds;
        playMedia();
      }
    }
  };

  const changeCurrentTimeBoth = (newCurrentTime) => {
    pauseMedia();
    if (newCurrentTime !== "" && newCurrentTime !== null) {
      // hh:mm:ss:ff - mm:ss - m:ss - ss - seconds number or string and hh:mm:ss
      const newCurrentTimeInSeconds = timecodeToSeconds(newCurrentTime);
      const videoRefs = videoRef.current;

      if (videoRefs.readyState === 4) {
        videoRefs.currentTime = newCurrentTimeInSeconds;
        // playMedia();
      }
    }
  };

  const rollBack = () => {
    // get video duration
    const videoElem = videoRef.current;
    const tmpDesiredCurrentTime =
      videoElem.currentTime - rollBackValueInSeconds;
    // > 0 < duration of video
    changeCurrentTime(tmpDesiredCurrentTime);
  };

  const handlePlayBackRateChange = (e) => {
    setPlaybackRate(parseFloat(e.target.value));
  };

  const handleMuteVolume = () => {
    if (videoRef.current.volume > 0) {
      videoRef.current.volume = 0;
      setIsMute(true);
    } else {
      videoRef.current.volume = 1;
      setIsMute(false);
    }
  };

  const togglePlayMedia = () => {
    if (isPlaying) {
      pauseMedia();
    } else {
      playMedia();
    }
  };

  const skipForward = () => {
    const currentTime = videoRef.current.currentTime;
    const newCurrentTimeIncreased = currentTime + 2;
    const newCurrentTime = Number(newCurrentTimeIncreased.toFixed(1));

    changeCurrentTimeBoth(newCurrentTime);
  };

  const skipBackward = () => {
    const currentTime = videoRef.current.currentTime;
    const newCurrentTimeIncreased = currentTime - 2;
    const newCurrentTime = Number(newCurrentTimeIncreased.toFixed(1));

    changeCurrentTimeBoth(newCurrentTime);
  };

  const handleProgressBarClick = (e) => {
    const time = e.target.value;
    changeCurrentTimeBoth(time);
  };

  const getMediaCurrentTime = secondsToTimecode(
    videoRef.current.currentTime + timecodeOffset,
  );

  const handlePictureInPicture = () => {
    if (document.pictureInPictureElement !== undefined) {
      // from https://developers.google.com/web/updates/2017/09/picture-in-picture
      if (!document.pictureInPictureElement) {
        videoRef.current.requestPictureInPicture().catch((error) => {
          console.error("Video failed to enter Picture-in-Picture mode", error);
        });
      } else {
        document.exitPictureInPicture().catch((error) => {
          console.error("Video failed to leave Picture-in-Picture mode", error);
        });
      }
    } else {
      alert("Picture in Picture not supported in this browser, try chrome.");
    }
  };

  const getProgressBarMax = parseInt(videoRef.current.duration).toString();

  const getProgressBarValue = parseInt(videoRef.current.currentTime).toString();

  const promptSetCurrentTime = () => {
    let userTimecodeValue = prompt(
      "Jump to time - hh:mm:ss:ff hh:mm:ss mm:ss m:ss m.ss seconds",
    );
    if (userTimecodeValue !== null) {
      if (userTimecodeValue.includes(":")) {
        userTimecodeValue = timecodeToSeconds(userTimecodeValue);
      }
      // remove timecode offset if preset
      if (timecodeOffset !== 0) {
        userTimecodeValue -= timecodeOffset;
      }

      changeCurrentTime(userTimecodeValue);
    }
  };

  return (
    <>
      <MediaPlayerSection>
        <MediaPlayerPlayerSection>
          <MediaPlayerControlsSection>
            <MediaPlayerTitle>{title}</MediaPlayerTitle>
            <Separator />
            <PlayerControls
              playMedia={togglePlayMedia}
              isPlaying={isPlaying}
              isMute={isMute}
              playbackRate={playbackRate}
              skipBackward={skipBackward}
              skipForward={skipForward}
              rollback={rollBack}
              currentTime={getMediaCurrentTime}
              duration={mediaDuration}
              onSetCurrentTime={""}
              handleMuteVolume={handleMuteVolume}
              setPlayBackRate={handlePlayBackRateChange}
              promptSetCurrentTime={promptSetCurrentTime}
              pictureInPicture={handlePictureInPicture}
              mediaType={mediaType}
              playMediaFile={playMedia}
              {...{ pauseMedia, videoRef }}
            />
            {mediaUrl ? (
              <ProgresBar
                {...{
                  getProgressBarMax,
                  getProgressBarValue,
                  handleProgressBarClick,
                }}
              />
            ) : null}
          </MediaPlayerControlsSection>
        </MediaPlayerPlayerSection>
      </MediaPlayerSection>
    </>
  );
};

export default MediaPlayer;

const MediaPlayerSection = styled.section`
  background: ${COLORS.PRIMARY_WHITE};
  border-radius: 5px;
  box-shadow: 0 0 5px ${COLORS.BTN_GREEN};
`;
const MediaPlayerPlayerSection = styled.div`
  display: inline-flex;
  align-items: flex-start;
  width: 100%;
`;
const MediaPlayerControlsSection = styled.div`
  text-align: center;
  width: 100%;
  margin: auto;
  padding: 1em;
  position: relative;
  // overflow-x: auto;
  // overflow-y: auto;
`;
const MediaPlayerTitle = styled.h2`
  color: ${COLORS.COLOR_DARK};
  line-height: 1.2em;
  width: 100%;
  margin-top: 0;
  margin-bottom: 0.5em;
  font-size: 1.2em;
  overflow: hidden;
  display: grid;
  /* white-space: nowrap; */
  text-overflow: ellipsis;
`;
const Separator = styled.div`
  margin-bottom: 0.2em;
`;
// export const TopSection = styled.section`
//     background: ${COLORS.BTN_GREEN};
// `
// export const PlayerSection = styled.div`
//     display: inline-flex;
//     align-items: flex-start;
//     width: 100%;
//     color: ${COLORS.BTN_GREEN}
// `

// export const ControlsSection = styled.div`
//     text-align: center;
//     // width: 100%;
//     margin: auto;
//     padding: 1em;
//     position: relative;
//     background: ${COLORS.BTN_GREEN}
// `

// export const Title = styled.h2`
//     color: ${COLORS.BTN_GREEN}
//     line-height: 1.2em;
//     width: 60%;
//     margin-top: 0;
//     margin-bottom: 0.5em;
//     font-size: 1.2em;
//     overflow: hidden;
//     display: inline-block;
//     white-space: nowrap;
//     text-overflow: ellipsis;
// `

// const Container = styled.div`
//     margin: auto;
//     // padding: 1em;
//     padding-left: 200px;
//     position: relative;
//     align-items: flex-start;
//     width: 100%;
//     text-align: center;
// `
